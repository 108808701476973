<template>
  <div class="editorAttr">
    <div class="header flex">
      <div class="flex-1">
        <div style="width:120px;text-align:center;">值</div>
      </div>
      <div style="width:130px;text-align:center;">操作</div>
    </div>
    <div v-if="list && list.length>0" class="content" ref="attrLoad">
      <draggable
        class="list-group"
        v-model="list"
        @change="changeSort"
        v-bind="dragOptions"
        tag="ul"
      >
        <transition-group type="transition" name="flip-list">
          <li v-for="(item,idx) in list" :key="idx" aria-hidden="true" class="list-group-item flex">
            <div class="flex-1">
              <el-input v-if="popTitle == '类目'"
                :disabled="listId!=item.id"
                style="width:120px;margin-right:5px;"
                maxlength="8"
                size="mini"
                v-model="item.value"
                placeholder="请输入内容"
              ></el-input>
              <el-input v-else
                :disabled="listId!=item.id"
                style="width:120px;margin-right:5px;"
                maxlength="8"
                size="mini"
                v-model="item.name"
                placeholder="请输入内容"
              ></el-input>
              <el-button
                v-if="listId==item.id"
                style="margin-left:10px;"
                @click="editSubmit(item,idx)"
                type="text"
                size="mini"
              >确定</el-button>
              <el-button
                v-if="listId==item.id"
                style="margin-left:10px;"
                @click="closeSubmit(item,idx)"
                type="text"
                size="mini"
              >取消</el-button>
              <el-button
                v-if="listId!=item.id"
                style="margin-left:10px;"
                @click="editClass(item,idx)"
                type="text"
                size="mini"
              >修改</el-button>
            </div>
            <div style="width:100px;text-align:center;">
              <el-button @click="delClass(item,idx)" type="text" size="mini">删除</el-button>
            </div>
          </li>
        </transition-group>
      </draggable>
    </div>
    <div class="add flex" style="padding:10px 30px;">
      <div>
        <el-input
          style="width:120px;margin-right:5px;"
          size="mini"
          v-model="createdName"
          placeholder="请输入内容"
        ></el-input>
      </div>
      <div>
        <el-button size="mini" @click="addClass()" type="primary">添加</el-button>
      </div>
      <div class="font14-grey1" style="line-height:28px;margin-left:15px;color:#F66F6A">可拖拽排序</div>
    </div>

    <div style="padding-top:20px;" class="flex flex-justify-around">
      <el-button type="primary" size="small" @click="hide()" style="width: 80px;">关闭</el-button>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import {
  catAttrValueList,
  catAttrValueAdd,
  catAttrValueEdit,
  catAttrValueDel,
  catAttrValueSort,
  colorList,
  colorAdd,
  colorEdit,
  colorDel,
  colorSort,
  materialList,
  materialAdd,
  materialEdit,
  materialDel,
  materialSort,
} from "@/service/item";
export default {
  components: {
    draggable,
  },
  props: ["itemId", "popTitle"],
  data() {
    return {
      list: "",
      listId: null,
      createdName: "",
      colorValue: "",
    };
  },
  watch: {
    popTitle: {
      handler() {
        this.refreshItems();
      },
      deep: true,
    },
    itemId: {
      handler() {
        this.refreshItems();
      },
      deep: true,
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 100,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    this.refreshItems();
  },
  mounted() {},
  methods: {
    refreshItems() {
      console.log("refreshItems", this.itemId);
      const _loading = this.$loading({
        lock: true,
        target: this.$refs["attrLoad"],
        text: "拼命加载中......",
      });
      if (this.popTitle == "类目") {
        catAttrValueList(this.itemId)
          .then((rst) => {
            this.list = rst;
            _loading.close();
          })
          .catch((err) => {
            _loading.close();
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "颜色") {
        colorList()
          .then((rst) => {
            _loading.close();
            this.list = rst;
          })
          .catch((err) => {
            _loading.close();
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "材质") {
        materialList({ hasCommon: false })
          .then((rst) => {
            _loading.close();
            this.list = rst;
          })
          .catch((err) => {
            _loading.close();
            this.$message.error(err.message);
          });
      }
      console.log(this.list);
    },
    addClass() {
      if (!this.createdName) {
        return this.$message.error("请输入内容");
      }
      let length = 0;
      if (this.list && this.list.length > 0) {
        length = this.list.length;
      }
      if (this.popTitle == "类目") {
        catAttrValueAdd({
          value: this.createdName,
          catAttrId: this.itemId,
          order: length,
        })
          .then((rst) => {
            this.onSuccess();
            this.$message.success("添加成功");
            this.createdName = "";
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "颜色") {
        colorAdd({ name: this.createdName, order: length })
          .then((rst) => {
            this.onSuccess();
            this.$message.success("添加成功");
            this.createdName = "";
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "材质") {
        materialAdd({ name: this.createdName, order: length })
          .then((rst) => {
            this.onSuccess();
            this.$message.success("添加成功");
            this.createdName = "";
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },
    changeSort(e) {
      let sortList = [];
      this.list.forEach((e, idx) => {
        sortList.push({ id: e.id, order: idx });
      });
      if (this.popTitle == "类目") {
        catAttrValueSort(sortList)
          .then((rst) => {
            this.onSuccess();
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "颜色") {
        colorSort(sortList)
          .then((rst) => {
            this.onSuccess();
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "材质") {
        materialSort(sortList)
          .then((rst) => {
            this.onSuccess();
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },
    editClass(item, index) {
      this.listId = item.id;
    },
    editSubmit(item, index) {
      let obj = {
        id: item.id,
        name: item.name,
      };
      if (this.popTitle != "类目" && !item.name || this.popTitle=="类目" && !item.value) {
        return this.$message.error("请输入内容");
      }
      if (this.popTitle == "类目") {
        catAttrValueEdit({id: item.id,value:item.value,catAttrId:item.catAttrId})
          .then((rst) => {
            this.$message.success("修改成功");
            this.listId = null;
            this.onSuccess();
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "颜色") {
        colorEdit(obj)
          .then((rst) => {
            this.$message.success("修改成功");
            this.listId = null;
            this.onSuccess();
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else if (this.popTitle == "材质") {
        materialEdit(obj)
          .then((rst) => {
            this.$message.success("修改成功");
            this.listId = null;
            this.onSuccess();
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },
    closeSubmit() {
      this.listId = null;
      this.refreshItems();
    },
    delClass(item, index) {
      this.$confirm("确认删除?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.popTitle == "类目") {
          catAttrValueDel(item.id)
            .then((rst) => {
              this.$message.success("删除成功");
              this.onSuccess();
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        } else if (this.popTitle == "颜色") {
          colorDel(item.id)
            .then((rst) => {
              this.$message.success("删除成功");
              this.onSuccess();
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        } else if (this.popTitle == "材质") {
          materialDel(item.id)
            .then((rst) => {
              this.$message.success("删除成功");
              this.onSuccess();
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        }
      });
    },
    onSuccess() {
      this.$emit("onSuccess");
      this.refreshItems();
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
<style scoped>
.editorAttr .header {
  padding: 0 31px;
  border: 1px solid #ebeef5;
  background: #FAFAFA;
}
.editorAttr .header > div {
  font-weight: 600;
  color: #000;
  line-height: 38px;
}
.content {
  width: 100%;
  padding: 10px 0;
  border: 1px solid #ebeef5;
  border-top: none;
}
.content .list-group {
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 0;
}
.content .list-group>span {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.content .list-group .list-group-item {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
  border-radius: 5px;
}
.content .list-group .list-group-item:first-child {
  border-top: 1px solid #ebeef5;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb7a;
}

.add {
    border: 1px solid #ebeef5;
    border-top: none;
    background: #FAFAFA;
}
/* .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    } */
/* .list-group-item {
        cursor: move;
    } */
</style>